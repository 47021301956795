import { useState } from 'react';
import './App.css';

const Srpski = (props) => {
    const [type, setType] = useState("export")
    const [code, setCode] = useState("")
    const [isSendingInProgres, setIsSendingInProgres] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [baseTariff, setBaseTariff] = useState("")
    const [category, setCategory] = useState("")
    const [years, setYears] = useState([])


    const sendInquiry = () => {
        setIsSendingInProgres(true)

        const formData = new FormData();

        formData.append('type', type);
        const codeTrimmed = code.replace(/\D/g, '')
        formData.append('code', codeTrimmed);

        fetch("https://pks.ohdstaging.com/public-api/china-tariffs", {
            // fetch("https://api.pks.rs/public-api/china-tariffs", {

            method: 'POST',
            headers: {
                "Accept": 'application/json',
            },
            body: formData,
        }).then((res) => res.json())
            .then((result) => {
                if (result.body.error_fields) {
                    setErrorMsg("За унети тарифни број не постоје подаци у бази.")
                    setBaseTariff("")
                    setCategory("")
                    setYears([])
                } else {
                    setErrorMsg("")
                    setBaseTariff(result.body.entries[0].base_tariff)
                    setCategory(result.body.entries[0].category)
                    const yearValues = []
                    const resultArray = (Object.entries(result.body.entries[0]))
                    for (let i = 3; i < resultArray.length; i++) {
                        yearValues.push(resultArray[i])
                    }
                    setYears(yearValues)
                }
                setIsSendingInProgres(false)
            }).catch((error) => {
                setIsSendingInProgres(false)
            });
    }
    const handleChange = (event) => {
        if (event.key === 'Enter') {
            sendInquiry()
        }
    }
    return (
        <main>
            <div className='main-title-wrapper'>
                <h1>Царинске стопе на трговину производима<br />
                    према Споразуму о слободној трговини<br />
                    између Републике Србије и Народне Републике Кине</h1>
            </div>
            <div className='text-wrapper' dangerouslySetInnerHTML={{ __html: props.tekst_iznad }} />

            <div className='form-wrapper'>
                <label>Одаберите</label>
                <select onChange={(event) => setType(event.target.value)}>
                    <option value="export">Извоз из Србије у Кину</option>
                    <option value="import">Увоз у Србију из Кине</option>
                </select>
                <label>Унесите тарифни број</label>
                <input className={errorMsg && "error-input"} type="text" value={code} onChange={(event) => setCode(event.target.value)} onKeyDown={(event) => handleChange(event)} />
                <p className='disclaimer-msg'>{type === "export" ? "унесите тарифни број од 8 цифара" : "унесите тарифни број од 10 цифара"}</p>
                <p className='error-msg'>{errorMsg}</p>

                {!isSendingInProgres &&
                    <button onClick={sendInquiry}>
                        Претражи
                    </button>
                }
                {isSendingInProgres &&
                    <button className='disabled-btn'>
                        Претрага у току
                    </button>
                }


                <ul className='result-list'>
                    {baseTariff &&
                        <li className='result-list-item'><span className='list-item'>Базна царина %</span> <span className='list-item'>{baseTariff}</span></li>
                    }
                    {category &&
                        <li className='result-list-item'><span className='list-item'>Категорија</span> <span className='list-item'>{category}</span></li>
                    }
                    {years &&
                        years.map((year, i) => {
                            if (year[1].includes("kg")) {
                                return <li className='result-list-item' key={i}><span className='list-item'>Година {year[0].split("_")[1]} (%)</span> <span className='list-item'>{year[1]}</span></li>
                            } else {
                                return <li className='result-list-item' key={i}><span className='list-item'>Година {year[0].split("_")[1]} (%)</span> <span className='list-item'>{parseFloat(year[1]).toFixed(1)}</span></li>

                            }
                        })
                    }
                </ul>
            </div>
            <div className='text-wrapper' dangerouslySetInnerHTML={{ __html: props.tekst_ispod }} />
        </main >
    );
}

export default Srpski;
