import { useState, useEffect } from 'react';
import cyrLogo from "./logo_cir@2x.png"
import latLogo from "./logo_lat@2x.png"
import engLogo from "./pks_logo-eng.png"
import backgroundImg from "./find-supplier-background.png"
import Srpski from './Srpski';
import SrpskiLatinica from './SrpskiLatinica';
import Engleski from './Engleski';
import './App.css';

const App = () => {
  const [lang, setLang] = useState("")
  const [tekst_iznad, setTekst_iznad] = useState("")
  const [tekst_ispod, setTekst_ispod] = useState("")
  const [tekst_iznadLat, setTekst_iznadLat] = useState("")
  const [tekst_ispodLat, setTekst_ispodLat] = useState("")
  const [tekst_iznadEn, setTekst_iznadEn] = useState("")
  const [tekst_ispodEn, setTekst_ispodEn] = useState("")

  useEffect(() => {
    fetch("https://pks.ohdstaging.com/public-api/china-page", {
      // fetch("https://api.pks.rs/public-api/china-page", {

      method: 'GET',
      headers: {
        "Accept": 'application/json',
        "language": ""
      },
    }).then((res) => res.json()).then((result) => {
      setTekst_iznad(result.body.entry.tekst_iznad)
      setTekst_ispod(result.body.entry.tekst_ispod)
    })

    fetch("https://pks.ohdstaging.com/public-api/china-page", {
      // fetch("https://api.pks.rs/public-api/china-page", {

      method: 'GET',
      headers: {
        "Accept": 'application/json',
        "language": "sr_lat"
      },
    }).then((res) => res.json()).then((result) => {
      setTekst_iznadLat(result.body.entry.tekst_iznad)
      setTekst_ispodLat(result.body.entry.tekst_ispod)
    })

    fetch("https://pks.ohdstaging.com/public-api/china-page", {
      // fetch("https://api.pks.rs/public-api/china-page", {

      method: 'GET',
      headers: {
        "Accept": 'application/json',
        "language": "en"
      },
    }).then((res) => res.json()).then((result) => {
      setTekst_iznadEn(result.body.entry.tekst_iznad)
      setTekst_ispodEn(result.body.entry.tekst_ispod)
    })
  }, [])

  return (
    <div className="app">
      <img className='background-image' src={backgroundImg} alt="background" />

      <header className="header">
        {lang === "" && <img className='header-logo-cyr' src={cyrLogo} alt="logo" />}
        {lang === "sr_lat" && <img className='header-logo-cyr' src={latLogo} alt="logo" />}
        {lang === "en" && <img className='header-logo-eng' src={engLogo} alt="logo" />}

        <div className='lang-change-wrapper'>
          <div className='lang-btn cyr-btn' onClick={() => setLang("")}>Ћир</div>
          <div className='lang-btn cyr-btn' onClick={() => setLang("sr_lat")}>Lat</div>
          <div className='lang-btn' onClick={() => setLang("en")}>Eng</div>
        </div>
      </header>

      {lang === "" &&
        <Srpski tekst_iznad={tekst_iznad} tekst_ispod={tekst_ispod} />}
      {lang === "sr_lat" &&
        <SrpskiLatinica tekst_iznad={tekst_iznadLat} tekst_ispod={tekst_ispodLat} />}
      {lang === "en" &&
        <Engleski tekst_iznad={tekst_iznadEn} tekst_ispod={tekst_ispodEn} />}
    </div>
  );
}

export default App;
